import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertController, ModalController, NavParams } from '@ionic/angular';

import { AuthService } from 'src/services/auth.service';
import { MessageService } from 'src/services/message.service';
import { TagsService } from 'src/services/tags.service';

@Component({
  selector: 'app-edit-tag',
  templateUrl: './edit-tag.component.html',
  styleUrls: ['./edit-tag.component.scss'],
})
export class EditTagComponent implements OnInit {
  @ViewChild('nameInput', { static: false }) nameInput;

  isLoading = false;
  name: string;
  description: string;
  id?: string;
  autoTaggingEnabled = true;

  constructor(
    navParams: NavParams,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private msgSrvc: MessageService,
    private tagsService: TagsService,
    public authService: AuthService,
  ) {
    this.id = navParams.get('id');
  }

  ngOnInit(): void {
    if (this.id) {
      this.loadTag();
    } else {
      setTimeout(() => this.nameInput.setFocus(), 500);
    }
  }

  async loadTag() {
    try {
      const tag = await this.tagsService.getById(this.id);

      this.name = tag.name;
      this.description = tag.description;
      this.autoTaggingEnabled = tag.autoTaggingEnabled;
    } catch (error) {
      await this.msgSrvc.showError(error);
    }
  }

  async submit() {
    if (!this.name) {
      await this.msgSrvc.show('Whoops! Please enter a name.');

      return;
    }

    this.isLoading = true;

    try {
      if (this.id) {
        await this.tagsService.updatePartial(this.id, {
          name: this.name,
          description: this.description,
          autoTaggingEnabled: this.autoTaggingEnabled,
        });

        await this.msgSrvc.show('Tag updated!');
      } else {
        await this.tagsService.create({
          name: this.name,
          description: this.description,
          autoTaggingEnabled: this.autoTaggingEnabled,
        });

        await this.msgSrvc.show('Tag added!');
      }

      this.dismiss();
    } catch (error) {
      await this.msgSrvc.showError(error);
    } finally {
      this.isLoading = false;
    }
  }

  async delete() {
    const alert = await this.alertCtrl.create({
      header: 'Are you sure?',
      subHeader: `This can't be undone`,
      buttons: [
        {
          text: 'Nevermind',
        },
        {
          text: 'Delete',
          handler: async () => {
            try {
              this.isLoading = true;
              await this.tagsService.delete(this.id);
              this.isLoading = false;
              this.dismiss();
            } catch (e) {
              this.isLoading = false;
              this.msgSrvc.show(`Unable to delete tag: ${e}`);
            }
          },
        },
      ],
    });
    alert.present();
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
