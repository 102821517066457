<ion-header class="modal-header">
  <div class="modal-title">
    <h3>{{ id ? 'Edit Tag' : 'Add Tag' }}</h3>
  </div>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button fill="clear" shape="round" class="close-button" (click)="dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div style="padding: 16px">
    <ion-label class="input-label"> Name </ion-label>
    <ion-input #nameInput placeholder="e.g. Super users" [(ngModel)]="name" class="input-field"> </ion-input>
    <ion-label class="input-label"> Description </ion-label>
    <ion-textarea
      #descriptionInput
      placeholder="e.g. Customers who heavily use the product"
      [(ngModel)]="description"
      class="input-field"
      rows="8"
    >
    </ion-textarea>
    <div
      style="width: 100%; display: flex; align-items: center; justify-content: space-between"
      *ngIf="authService.tenant.aiFeatures?.autoTagging"
    >
      <ion-label>Tag can be applied automatically by AI?</ion-label>
      <label class="switch" slot="end">
        <input type="checkbox" [(ngModel)]="autoTaggingEnabled" />
        <span class="slider"></span>
      </label>
    </div>
    <p class="subtitle" *ngIf="authService.tenant.aiFeatures?.autoTagging">
      <b>Tip:</b> adding more detailed descriptions will result in more accurate
      <a href="https://docs.unthread.io/ai/ai-tags" target="_blank">AI-automated tags</a>
    </p>
  </div>
</ion-content>

<ion-footer class="footer flex-space-between">
  <ion-button (click)="submit()" [disabled]="isLoading">
    Save tag
    <ion-spinner name="crescent" [hidden]="!isLoading" style="margin-left: 10px"></ion-spinner>
  </ion-button>
  <ion-button color="danger" fill="clear" (click)="delete()" [disabled]="isLoading" *ngIf="id"> Delete </ion-button>
</ion-footer>
